

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ButtonWithConfirmation",

  props: {
    error: { type: String },
    value: { type: Boolean },
    isPerforming: { type: Boolean },
    newValue: { type: Boolean, required: true },
    maxWidth: { type: Number, default: 600 },
  },

  computed: {
    dialogTitle(): string {
      const titleType = this.newValue ? "titleGrant" : "titleRevoke";
      return this.$t(`views.betaAccess.changeDialog.${titleType}`).toString();
    },
  },

  methods: {
    handleCancel() {
      this.$emit("input", false);
      this.$emit("cancel");
    },
  },
});
