import urls from "@/config/urls";

import Repository from "./repository";
import { getJsonData } from "./helpers";
import { BetaOrganizationUiDto } from "@/models/BetaOrganizationUiDto";
import { BetaOrganizationChangesUiDto } from "@/models/BetaOrganizationChangesUiDto";

export interface IBetaAccessRepository {
  fetchOrganizations(): Promise<BetaOrganizationUiDto[]>;
  updateBetaAccess(orgaId: string, value: boolean): Promise<BetaOrganizationUiDto>;
}

// TODO: Add types for the responses
class BetaAccessRepository implements IBetaAccessRepository {
  public async fetchOrganizations(): Promise<BetaOrganizationUiDto[]> {
    const response = await Repository.get(urls.api.betaAccess.organizations);

    return getJsonData(response) as BetaOrganizationUiDto[];
  }

  public async updateBetaAccess(orgaId: string, hasBetaAccess: boolean): Promise<BetaOrganizationUiDto> {
    const params: BetaOrganizationChangesUiDto = { hasBetaAccess };
    const response = await Repository.patch(urls.api.betaAccess.update(orgaId), params);

    return getJsonData(response) as BetaOrganizationUiDto;
  }
}

export default new BetaAccessRepository();
