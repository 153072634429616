



























































import { defineComponent } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import ChangeBetaAccessDialog from "./BetaAccess/ChangeBetaAccessDialog.vue";

import { ACTION_BETA_ACCESS_PAGE_SHOW } from "@/config/authActions";
import setPageTitle from "@/compositions/setPageTitle";
import connectUserHub from "@/compositions/connectUserHub";
import betaAccessRepository from "@/repositories/betaAccessRepository";

// Includes padding bottom and footer
const PADDING = 120;

export default defineComponent({
  name: "MembersPage",

  components: {
    PageHeader,
    ChangeBetaAccessDialog,
  },

  data() {
    const query = this.$route.query;

    return {
      changeThis: null as { orgaId: string; value: boolean } | null,

      loading: false,
      showUpdateWarningDialog: false,
      isUpdating: false,
      organizations: [] as any[],
      updateError: null as string | null,

      initialSearch: query.search || "",
      search: query.search || "",
    };
  },

  setup() {
    setPageTitle("views.betaAccess.pageTitle");
    connectUserHub();
  },

  mounted() {
    this.fetchOrganizations();
    if (!this.$refs.tableRef) {
      return;
    }

    const element = (this.$refs.tableRef as any).$el as HTMLElement;
    const top = element.getBoundingClientRect().top;
    const maxHeight = window.innerHeight - top - PADDING;
    element.style.maxHeight = `${maxHeight}px`;
  },

  computed: {
    canDoRule(): string {
      return ACTION_BETA_ACCESS_PAGE_SHOW;
    },

    headers(): object[] {
      return [
        {
          text: this.$t("views.betaAccess.headers.name"),
          value: "name",
          class: "qa-header-name",
        },
        {
          text: this.$t("views.betaAccess.headers.hasBetaAccess"),
          value: "hasBetaAccess",
          class: "qa-header-beta-access",
        },
      ];
    },
  },

  methods: {
    async fetchOrganizations() {
      this.loading = true;
      try {
        this.organizations = await betaAccessRepository.fetchOrganizations();
      } catch (e) {
        console.error("send error");
      }
      this.loading = false;
    },

    async updateAccess() {
      this.isUpdating = true;
      this.updateError = null;
      try {
        await betaAccessRepository.updateBetaAccess(this.changeThis!.orgaId, this.changeThis!.value);
        this.updateItem(this.changeThis!.orgaId, this.changeThis!.value);
        this.showUpdateWarningDialog = false;
        this.changeThis = null;
      } catch (e) {
        this.updateError = this.$t("views.betaAccess.serverError").toString();
      }
      this.isUpdating = false;
    },

    updateItem(orgaId: string, hasBetaAccess: boolean) {
      this.organizations = this.organizations.map((orga) =>
        orga.id === orgaId
          ? {
              ...orga,
              hasBetaAccess,
            }
          : orga
      );
    },

    handleBetaAccessChange(orgaId: string, value: boolean) {
      this.changeThis = {
        orgaId,
        value,
      };
      this.showUpdateWarningDialog = true;
    },

    restoreAccess() {
      this.updateItem(this.changeThis!.orgaId, !this.changeThis!.value);
      this.changeThis = null;
    },
  },
});
