import { render, staticRenderFns } from "./ChangeBetaAccessDialog.vue?vue&type=template&id=300f106c&scoped=true&"
import script from "./ChangeBetaAccessDialog.vue?vue&type=script&lang=ts&"
export * from "./ChangeBetaAccessDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeBetaAccessDialog.vue?vue&type=style&index=0&id=300f106c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "300f106c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VDialog})
